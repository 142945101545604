.image-list {
  columns: 6 200px;
  column-gap: 10px;
}

img {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 8px; /* Add rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

/* Add hover effect for images */
.image-frame:hover img {
  transform: scale(1.05);
  transition: transform 0.3s ease;
}

.action-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  font-size: 16px;
  transition: background-color 0.3s;
}

.download-button {
  background-color: #007bff;
}

.share-button {
  background-color: #28a745;
}

.action-button:hover {
  background-color: #0056b3;
}
